import Text404SVG from '@components/Text404SVG';
import Button from '@components/Button';
import SendsparkLogo from '@components/SendsparkLogo';
import Link from '@components/Link';
import Title from '@components/Title';
import ErrorPageLayout from '@components/ErrorPageLayout';
import ErrorMessage from '@components/ErrorMessage';
import Background404Wrapper from '@styles/views/Background404Wrapper';
import ContentWrapper from '@styles/views/ContentWrapper';

const Custom404Page = () => {
  const handleSignUpFree = () => {
    window.open(
      `${process.env.NEXT_PUBLIC_DEFAULT_ORIGIN || ''}/signup`,
      '_self'
    );
  };
  return (
    <ErrorPageLayout>
      <Background404Wrapper>
        <Text404SVG />
      </Background404Wrapper>
      <ContentWrapper>
        <div>
          <SendsparkLogo />
        </div>
        <div>
          <Title>We can’t find this page!</Title>
        </div>
        <ErrorMessage>
          Sorry, but it seems this page doesn’t exist anymore... It would be a
          great place for a video, though.
        </ErrorMessage>
        <div>
          <Button onClick={handleSignUpFree}>Sign Up for Free</Button>
        </div>
        <div>
          <Link href={process.env.NEXT_PUBLIC_SENDSPARK_SITE}>
            Visit Homepage
          </Link>
        </div>
      </ContentWrapper>
    </ErrorPageLayout>
  );
};

export default Custom404Page;
