import styled from 'styled-components';

const Title = styled.h1<{ fontWeight?: string }>`
  color: #100b2b;
  font-family: ${({ fontWeight }) =>
      fontWeight ? `Inter UI ${fontWeight}` : 'Inter UI Bold'},
    sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: bold;
  line-height: 30px;
  text-align: center;
`;

export default Title
