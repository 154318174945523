import styled from 'styled-components';

const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 40px;
  position: relative;
  z-index: 2;

  div {
    margin: 0 0 24px 0;
  }
  div:last-child {
    margin: 0;
  }

  @media screen and (max-width: 500px) {
    padding: 0 20px;
  }
`;

export default ContentWrapper;
