import { FC } from 'react';

interface Text404SVGProps {
  height?: string;
  color?: string;
  width?: string;
}

const Text404SVG: FC<Text404SVGProps> = ({
  height = '253',
  width = '581',
  color = '#100B2B',
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.02">
      <path
        d="M182.711 154.877H159.444V5.12837H107.094L0 161.373V198.297H113.595V247.872H159.444V198.297H182.711V154.877ZM113.595 70.0878V154.877H55.0869L113.253 70.0878H113.595Z"
        fill={color}
      />
      <path
        d="M293.237 253C352.43 253 381.513 200.007 381.513 126.5C381.513 52.9932 352.43 0 293.237 0C234.044 0 204.961 52.9932 204.961 126.5C204.961 200.007 234.044 253 293.237 253ZM293.237 47.1811C318.557 47.1811 330.874 71.4554 330.874 126.5C330.874 181.545 318.557 205.819 293.237 205.819C267.918 205.819 255.6 181.545 255.6 126.5C255.6 71.4554 267.918 47.1811 293.237 47.1811Z"
        fill={color}
      />
      <path
        d="M581 154.877H557.734V5.12837H505.384L398.289 161.373V198.297H511.885V247.872H557.734V198.297H581V154.877ZM511.885 70.0878V154.877H453.376L511.543 70.0878H511.885Z"
        fill={color}
      />
    </g>
  </svg>
);

export default Text404SVG;
