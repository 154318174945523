import styled from 'styled-components';

const Button = styled.button`
  align-items: center;
  background-color: #6140ff;
  border-radius: 4px;
  border: none;
  color: #fffaf5;
  cursor: pointer;
  display: flex;
  font-family: 'Inter UI', 'sans-serif';
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  height: 40px;
  justify-content: center;
  line-height: 24px;
  margin: 0 auto;
  padding: 8px 16px;
  text-align: center;

  :hover {
    background-color: #6a46ff;
  }
`;

export default Button;
