import styled from 'styled-components';

const ErrorPageLayout = styled.div`
  left: 50%;
  max-width: 490px;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);

  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;

export default ErrorPageLayout;
