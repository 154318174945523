import styled from 'styled-components';

const ErrorMessage = styled.div`
  color: #100b2b;
  font-family: 'Inter UI', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  opacity: 0.64;
  text-align: center;
`;

export default ErrorMessage;
