import styled from 'styled-components';

const Link = styled.a`
  color: #6140ff;
  font-family: 'Inter UI Medium', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  text-decoration-line: underline;

  :hover {
    color: #4f2eea;
  }
`;

export default Link;
